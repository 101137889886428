interface Language {
  flagImgPath: string;
  code_intl: string;
  abbrev: string;
}

const languages: { [key: string]: Language } = {
  en: {
    flagImgPath: '/navbar/england.svg',
    code_intl: 'english_language',
    abbrev: 'en',
  },
  fr: {
    flagImgPath: '/navbar/france.svg',
    code_intl: 'french_language',
    abbrev: 'fr',
  },
  ru: {
    flagImgPath: '/navbar/russia.svg',
    code_intl: 'russian_language',
    abbrev: 'ru',
  },
};

export default languages;
