// export all hooks files
export * as teamlist from './teamlist';
export * as paymentLogos from './paymentLogos';
export * as appRoutes from './routes';
export * as contacts from './contacts';

// others
export const ITEMS_PER_PAGE = 10;

export const LAUNCH_DATE = '2024-04-01';
