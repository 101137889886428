export interface Cryptocurrency {
  code_intl: string;
  svgImage: string;
}

export const cryptocurrencies: { [key: string]: Cryptocurrency } = {
  bitcoin: {
    code_intl: 'bitcoin',
    svgImage: '/payments/bitcoin.svg',
  },
  ethereum: {
    code_intl: 'ethereum',
    svgImage: '/payments/ethereum.svg',
  },

  tron: {
    code_intl: 'tron',
    svgImage: '/payments/tron.svg',
  },
  tether: {
    code_intl: 'tether',
    svgImage: '/payments/tether.svg',
  },
};

export const paymentsLogo: string[] = [
  '/payments/wave.jpeg',
  '/payments/momo.png',
  '/payments/om.jpeg',
  '/payments/airtel.jpeg',
  '/payments/mpesa.jpeg',
  '/payments/sber.png',
  '/payments/tinkoff.jpg',
  '/payments/cbp.jpeg',
  '/payments/1xbet.jpeg',
  '/payments/1win.jpeg',
  '/payments/betclic.jpeg',
];

export const cryptoCurrencies = [
  { name: 'Bitcoin', imageUrl: '/cryptocurrencies/bitcoin.png' },
  { name: 'Ethereum', imageUrl: '/cryptocurrencies/ethereum.png' },
  { name: 'Tether', imageUrl: '/cryptocurrencies/tether.png' },
  { name: 'Tron', imageUrl: '/cryptocurrencies/tron.png' },
  { name: 'BNB', imageUrl: '/cryptocurrencies/bnb.png' },
  { name: 'SOLANA', imageUrl: '/cryptocurrencies/solana.png' },
  { name: 'USD Coin', imageUrl: '/cryptocurrencies/usd-coin.png' },
  { name: 'TON', imageUrl: '/cryptocurrencies/ton.png' },
];
