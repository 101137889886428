type TeamMember = {
  name: string;
  role: string;
  picture: string;
};

export const teamlist: TeamMember[] = [
  {
    name: 'Wilfrid O.',
    role: 'C.E.O - Afiwa',
    picture: '/team/afiwa-wilfrid-ceo.jpeg',
  },
  {
    name: 'Natalya K.',
    role: 'Marketing Manger - Afiwa',
    picture: '/team/afiwa-natalya-manager.jpeg',
  },

  {
    name: 'Cedric Fonguh',
    role: 'C.T.O - Afiwa',
    picture: '/team/afiwa-cedric-fonguh.jpeg',
  },
  {
    name: 'Marcelle S.',
    role: 'Community Manger',
    picture: '/team/afiwa-marcelle-cm.jpeg',
  },
  // {
  //   name: 'Christian Kamgo',
  //   role: 'C.O.O - Afiwa',
  //   picture: '/team/afiwa-cedric-fonguh.jpg',
  // },
];
