import React from 'react';
import { useTranslations } from 'next-intl';
import { Link } from '@/intl/navigation';
import { contacts } from '@/constants';
import Image from 'next/image';
import { ArrowRightIcon } from '@heroicons/react/20/solid';

interface SocialNetworkButtonProps {
  socialNetworkName: string;
  icon: React.ReactElement;
  bgColor: string;
  href: string;
  verbose?: boolean;
}

export default function LiveSupport() {
  const supporttext = useTranslations('support');
  return (
    <div className="md:p-4">
      <Image
        src="/logos/afiwa-exchange.png"
        alt="Blue logo"
        width={200}
        height={60}
        className="-ms-2 mb-3"
      />
      <div className="flex flex-col">
        <div className=" flex flex-col gap-y-1 text-sm font-semibold md:text-base ">
          <span className=" text-primary-dark">
            {supporttext('titlePart1')}
          </span>
          <span className=" text-primary-dark">
            {supporttext('titlePart2')}
          </span>
        </div>
        <div className="text-left text-xs font-light text-primary-pale  md:text-sm">
          {supporttext('paragraph1')}
        </div>
      </div>
      <div className="flex flex-col space-y-3 py-5">
        <SocialNetworkButton
          verbose
          href={contacts.afiwaSocials['whatsapp'].link}
          socialNetworkName="WhatsApp"
          bgColor="bg-whatssap"
          icon={
            <Image
              src={'/socialMedia/white-whatsapp.svg'}
              alt="whatsapp-icon"
              height={26}
              width={25}
            />
          }
        />
        <SocialNetworkButton
          verbose
          href={contacts.afiwaSocials['telegram'].link}
          socialNetworkName="Telegram"
          bgColor="bg-telegram"
          icon={
            <Image
              src={'/socialMedia/white-telegram.svg'}
              alt="telegram-icon"
              height={26}
              width={25}
            />
          }
        />
      </div>
    </div>
  );
}

const SocialNetworkButton: React.FC<SocialNetworkButtonProps> = ({
  socialNetworkName,
  icon,
  bgColor,
  verbose,
  href,
}) => {
  const supporttext = useTranslations('support');
  return (
    <Link
      href={href}
      target="_blank"
      className={`${bgColor} flex items-center justify-between rounded-3xl p-2 shadow-md transition-all hover:scale-[1.03] md:p-3`}
    >
      <div className="flex items-center justify-between gap-x-2">
        {icon}
        <div className="flex flex-col space-y-0 text-white">
          {verbose && (
            <span className="text-gray-light text-left text-xs font-normal">
              {supporttext('socialMessage')}
            </span>
          )}
          <span className="text-left font-bold tracking-tighter">
            {socialNetworkName}
          </span>
        </div>
      </div>
      <ArrowRightIcon className="w-8 text-white" />
    </Link>
  );
};

export function TransactionSupport() {
  const supporttext = useTranslations('support');
  return (
    <div className="p-3 md:p-4">
      <div className="flex flex-col">
        <div className=" flex flex-col gap-y-1 text-sm font-semibold md:text-base ">
          <span className=" text-primary-dark">
            {supporttext('titlePart1')}
          </span>
          <span className=" text-primary-dark">
            {supporttext('titlePart2')}
          </span>
        </div>
        <div className="text-left text-xs font-light text-primary-pale  md:text-sm">
          {supporttext('paragraph1')}
        </div>
      </div>
      <div className="flex flex-col space-y-3 py-5">
        <SocialNetworkButton
          href={contacts.afiwaSocials['whatsapp'].link}
          socialNetworkName="WhatsApp"
          bgColor="bg-whatssap"
          icon={
            <Image
              src={'/socialMedia/white-whatsapp.svg'}
              alt="whatsapp-icon"
              height={26}
              width={25}
            />
          }
        />
        <SocialNetworkButton
          href={contacts.afiwaSocials['telegram'].link}
          socialNetworkName="Telegram"
          bgColor="bg-telegram"
          icon={
            <Image
              src={'/socialMedia/white-telegram.svg'}
              alt="telegram-icon"
              height={26}
              width={25}
            />
          }
        />
      </div>
    </div>
  );
}
