'use client';
import React from 'react';
import { Modal } from 'antd';
import LiveSupport from '@/ui/widgets/liveSupport';

type Props = {
  position?: string;
  visible: boolean;
  closeDrawer: () => void;
};

const SupportBubble = ({ position, visible, closeDrawer }: Props) => {
  return (
    <>
      <Modal
        title={null}
        footer={null}
        centered
        open={visible}
        onCancel={closeDrawer}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: true }}
        className={` ${position} md:!max-w-sm`}
      >
        <LiveSupport />
      </Modal>
    </>
  );
};

export default SupportBubble;
