'use client';
import { useTransition } from 'react';
import { usePathname, useRouter } from '@/intl/navigation';
import { useLocale } from 'next-intl';

interface LangHook {
  currentLanguage: string;
  switchLanguage: (l: string) => void;
}
function useLanguage(): LangHook {
  const [isPending, startTransition] = useTransition();
  const router = useRouter();
  const pathname = usePathname();
  const currentLanguage = useLocale();

  function switchLanguage(newLanguage: string): void {
    startTransition(() => {
      const newPathname = `${newLanguage}/${pathname}`;
      router.replace(pathname, { locale: newLanguage });
    });
  }

  return { currentLanguage, switchLanguage };
}

export default useLanguage;
