import React from 'react';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import appRoutes from '@/constants/routes';
import { Link } from '@/intl/navigation';

type Props = {
  userName: string;
  color?: string;
  handleClick?: () => void;
  handleMouseEnter?: () => void;
  handleMouseLeave?: () => void;
};

function UserAvatarName({
  userName,
  color,
  handleClick,
  handleMouseEnter,
  handleMouseLeave,
}: Props) {
  return (
    <div
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`${color}  flex cursor-pointer flex-row items-center gap-1`}
    >
      <UserCircleIcon className="h-8 w-8" />
      <span className="hidden text-sm md:block md:text-base">{userName}</span>
    </div>
  );
}

export default UserAvatarName;
