'use client';
import React from 'react';
import { Button, ButtonProps } from 'antd';
import { Bars3Icon } from '@heroicons/react/20/solid';

interface Props extends ButtonProps {
  onClick: () => void;
}

function BurgerMenu({ onClick, ...rest }: Props) {
  return (
    <Button
      {...rest}
      size="large"
      icon={<Bars3Icon height={28} width={28} />}
      onClick={onClick}
    />
  );
}

export default BurgerMenu;
