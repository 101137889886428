'use client';
import React, { useState, useRef } from 'react';
import { useTranslations } from 'next-intl';
import useLanguage from '@/hooks/useLanguage';
import languages from '@/constants/languages';
import Image from 'next/image';

// Define the structure for the Language object
interface Language {
  flagImgPath: string;
  code_intl: string;
  abbrev: string;
}

// Define the props for the DropdownMenu component
interface DropdownMenuProps {
  languages: { [key: string]: Language };
}

// LanguageSelector component
const LanguageSelector: React.FC = () => {
  const { currentLanguage, switchLanguage } = useLanguage(); // Custom hook to manage the current language
  const t = useTranslations('navigation'); // Hook to get translation functions for the 'navigation' namespace
  const [openDropdown, setOpenDropdown] = useState<boolean>(false); // State to track if the dropdown menu is open
  const dropdownTimeout = useRef<NodeJS.Timeout | null>(null); // Ref to hold the timeout for closing the dropdown

  // Function to handle mouse enter event
  const handleMouseEnter = () => {
    if (dropdownTimeout.current) {
      clearTimeout(dropdownTimeout.current); // Clear the timeout if it exists
    }
    setOpenDropdown(true); // Open the dropdown
  };

  // Function to handle mouse leave event
  const handleMouseLeave = () => {
    dropdownTimeout.current = setTimeout(() => {
      setOpenDropdown(false); // Close the dropdown after 200ms
    }, 200);
  };

  // Function to handle language change
  const handleLanguageChange = (key: string) => {
    switchLanguage(languages[key].abbrev); // Switch to the selected language
  };

  // Dropdown menu component to display available languages
  const DropdownMenu: React.FC<DropdownMenuProps> = ({ languages }) => {
    // Function to render each menu item
    const renderMenuItem = (key: string) => (
      <div
        key={key}
        className="flex min-w-[102px] cursor-pointer items-center rounded-md px-3 py-2 text-sm text-gray-700"
        role="menuitem"
        onClick={() => handleLanguageChange(key)}
      >
        {/* Render the SVG flag */}
        <Image
          src={languages[key].flagImgPath}
          alt="flag-icon"
          height={30}
          width={30}
          className={'rounded-lg border bg-primary'}
        />
        {/* Render the international code with translation */}
        <span className="mx-1  flex  text-center text-base font-extrabold text-primary-pale transition ease-in-out hover:text-[#35A100] md:mx-1">
          {t(languages[key].code_intl)}
        </span>
      </div>
    );

    // Return the rendered menu items by mapping over the language keys
    return (
      <div className="md absolute z-40">
        <div
          className="flex flex-col items-center justify-center overflow-hidden rounded-md bg-white shadow-xl"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {Object.keys(languages)
            .filter((key) => !currentLanguage.includes(key))
            .map(renderMenuItem)}
        </div>
      </div>
    );
  };

  return (
    // Wrapper div to conditionally hide the component based on 'hidden' prop
    <div
      className={`relative `}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* Toggle button to open/close the dropdown */}
      <div
        onClick={() => setOpenDropdown(!openDropdown)}
        className="relative flex cursor-pointer items-center justify-center rounded-md py-2 text-sm  md:px-3"
        role="menuitem"
      >
        {/* Render the current language's SVG flag */}
        <Image
          src={languages[currentLanguage].flagImgPath}
          alt="flag-icon"
          className={'rounded-lg'}
          height={30}
          width={30}
        />
        {/* Render the current language's international code */}
        <span
          className={`mx-1 flex text-center text-base font-extrabold leading-none text-primary-pale md:mx-1`}
        >
          {t(languages[currentLanguage].code_intl)}
        </span>
      </div>
      {/* Render the dropdown menu if openDropdown is true */}
      {openDropdown && <DropdownMenu languages={languages} />}
    </div>
  );
};

export default LanguageSelector;
